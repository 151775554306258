import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";

import { Img } from "react-image";
import SVG from 'react-inlinesvg';

const getFileExtension = (URL) => {
    const explodedURL = URL.split('.');
    return explodedURL[explodedURL.length - 1];
}

export default ({ size = 30, URL, className, color, animationData, loop = false, style}) => {

    // Check if there is a URL
    if( URL ) {
        // If so, check the extension of the URL
        const fileExt = getFileExtension(URL);
        // If file extension is SVG, return SVG component
        if( fileExt === 'svg' )
            return (
                <SVG
                    style={{height: size, maxWidth: '100%'}} //width: size, height: size,
                    preProcessor={code => code.replace(/fill=".*?"/g, `fill="${style.fill}"`)}
                    src={URL}
                />
            )
        // If not return image
        if( fileExt !== 'svg' )
            return (
                <Img
                    style={{height: size, maxWidth: '100%'}} //width: size, height: size,
                    src={URL}
                    loader={<CircularProgress size={size} />}
                    unloader={<CircularProgress size={size} />}
                />
            )
    }

/*    else if( animationData )
        return (
            <Lottie animationData={ animationData } autoplay loop={loop} width={size} height={size} style={{height: size, width: size}}/>
        )*/

    if(className)
        return(
            <Icon style={{ ...style, fontSize: size, color: color }} align="center" className={ className } />
        )

}