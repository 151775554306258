import {useCallback, useContext, useEffect} from "react";
import styled from 'styled-components';

import {PaymentContext} from "../../Contexts/PaymentContext";
import {useAPI} from "../../Hooks/useAPI";

const onloadF = (threeDSHtmlContent) => {
    document.getElementById("iframe").contentWindow.postMessage({mobilePayment: true, threeDSHtmlContent}, '*');
}

const customFrame = ({className, threeDSHtmlContent}) => (
    <iframe id="iframe" className={className} sandbox="allow-scripts allow-forms allow-same-origin" onLoad={() => onloadF(threeDSHtmlContent)} src="3dspage.html"/>
)

const ThreeDSFrame = styled(customFrame)`
  height: 55vh;
  width: 100%;
  min-height: 450px;

  border: none;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
`

const ThereDS = () => {

    const {threeDSHtmlContent, setModalInfo, setShowModal, setIsMemberLoading, language} = useContext(PaymentContext);
    const {modals} = language;
    const {refreshMember} = useAPI();

    const handler = useCallback(async ({error}) => {
        // If 3DS failed display error
        if (error === '3DSCannotComplete') {
            setModalInfo({
                infoType: "error",
                infoHeader: modals.error.payErrorHeader,
                infoText: modals.error.thereDSError,
            });
            setShowModal(true);
            setIsMemberLoading(false);
            return;
        }

        // If every thing is okay display success
        setModalInfo({
            infoType: "success",
            infoHeader: modals.success.paySuccessHeader,
            infoText: modals.success.paymentSuccess,
        });
        // Show Model
        setShowModal(true);
        await refreshMember();
        setIsMemberLoading(false);
    },[]);

    useEffect(() => {
        // Make member loading
        setIsMemberLoading(true);
        // Create a custom function and put it to the window object
        window.handle3DResult = handler;
    }, [])

    return (
        <Container>
            <ThreeDSFrame threeDSHtmlContent={threeDSHtmlContent}/>
        </Container>
    )
}

export default ThereDS;