const themeOptions = ({primary, backgroundColor, textColor, amountBackgroundColor, amountTextColor, paymentButtonBackgroundColor, paymentButtonTextColor}) => ({
    palette: {
        primary:{
            main: primary
        }
    },
    overrides: {
        MuiContainer: {
            root: {height: '100%', paddingTop: 40, paddingBottom: 30}
        },
        MuiToggleButtonGroup: {
            groupedHorizontal: {
                borderRadius: 0,
                '&:not(:first-child)': {marginLeft: 0},
                '&:first-child': {},
            }
        },
        MuiToggleButton: {
            root: {
                flexGrow: 2,
                backgroundColor: amountBackgroundColor,
                border: 'none',
                opacity: 0.5,
                color: amountTextColor,
                '&$selected': {
                    color: amountTextColor,
                    backgroundColor: amountBackgroundColor,
                    opacity: 1,
                    '&:hover': { backgroundColor: amountBackgroundColor },
                },
                '&:hover': { backgroundColor: amountBackgroundColor },
            },
        }
    },

    typography: {
        h3: {
            fontWeight: 600 // or 'bold'
        }
    },

    primary,
    paymentButtonBackgroundColor,
    paymentButtonTextColor,
    amountBackgroundColor,
    amountTextColor,
    textColor,
    pageBackground: backgroundColor
})

export default themeOptions;
