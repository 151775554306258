import React, {useEffect, useState} from "react";
import {Route, Switch, useLocation} from 'react-router-dom';

import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from "@material-ui/core/styles";

import PaymentContextProvider from "./Contexts/PaymentContext";

import {AnimatePresence} from "framer-motion";

import {useAPI} from "./Hooks/useAPI";

import GlobalStyle from "./Shared/GlobalStyle";

import FullPageLoading from "./Shared/FullPageLoading";
import PaymentPage from "./Payment";
import NewCardPage from "./NewCard";
import InfoModal from "./Shared/InfoModal/index";

const BusinessAndThemeProvider = ({children}) => {

    const [isBusinessLoading, setIsBusinessLoading] = useState(true);
    const [theme, setTheme] = useState({});

    const {getBusiness} = useAPI();

    useEffect(() => {
        const fetchBusiness = async () => {
            // Try to get business
            const {theme} = await getBusiness();

            // If there is no theme object,
            // that means cannot get business,
            // Keep displaying loading
            if (theme) {
                setTheme(theme)
                setIsBusinessLoading(false);
            }
        }
        fetchBusiness();
    }, [])

    return (
        <>
            {
                isBusinessLoading ?
                    <FullPageLoading/>
                    :
                    <ThemeProvider theme={theme}>
                        {children}
                    </ThemeProvider>
            }
        </>
    )

}

const App = () => {

    const location = useLocation();

    return (
        <PaymentContextProvider>
            <CssBaseline/>
            <BusinessAndThemeProvider>

                <GlobalStyle/>

                <AnimatePresence initial={false}>
                    <Switch location={location} key={location.pathname}>

                        <Route exact path="/" component={PaymentPage}/>
                        <Route exact path="/addNewCard" component={NewCardPage}/>

                    </Switch>
                </AnimatePresence>

                <InfoModal />

            </BusinessAndThemeProvider>
        </PaymentContextProvider>
    );
}

export default App;
