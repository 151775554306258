export const customValueMotion = {
    width: '100%',
    marginTop: 15,
    textAlign: 'center'
}

export const customValueError = {
    color: 'red',
    marginTop: 5
}

export default {customValueMotion, customValueError}