import {Grid, Paper, Typography} from "@material-ui/core";
import CustomIcon from "../Shared/CustomIcon";

export default ({cardType = 'addNew', bank, last4Digit, cardToken, text}) => {

    const cardImages = {
        "addNew": "https://vault.bonobo.com.tr/business-assets/mobilePayment/addNewCard.svg",
        "VISA": "https://vault.bonobo.com.tr/business-assets/mobilePayment/visa.png",
        "MAESTRO": "https://vault.bonobo.com.tr/business-assets/mobilePayment/maestro.png",
        "MASTER_CARD": "https://vault.bonobo.com.tr/business-assets/mobilePayment/mastercard.png",
        "MASTERCARD": "https://vault.bonobo.com.tr/business-assets/mobilePayment/mastercard.png",
        "TROY": "https://vault.bonobo.com.tr/business-assets/mobilePayment/troy.png",
        "AMEX": "https://vault.bonobo.com.tr/business-assets/mobilePayment/amex.png"
    }

    return (
        <Paper style={{width: '100%', padding: 10 }} id={cardToken}>
            <Grid container justifyContent="space-between" alignItems="center" id={cardToken}>
                <Grid container item xs={3} id={cardToken}>
                    <CustomIcon size={50} URL={cardImages[cardType] || cardImages["addNew"]} style={{fill:'grey'}} id={cardToken} />
                </Grid>
                <Grid item xs={9} value={cardToken}>
                    {
                        cardType === 'addNew' ?
                            <Typography variant="body2" style={{ textTransform: 'none' }}> {text} </Typography>
                            :
                            <>
                                <Typography variant="h6" id={cardToken}>{bank}</Typography>
                                <Typography variant="body2" id={cardToken}>**** **** **** {last4Digit}</Typography>
                            </>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}