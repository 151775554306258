import {Divider, Typography, Grid} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {useContext} from "react";
import {PaymentContext} from "../Contexts/PaymentContext";

const AmountSelector = () => {

    // Styling
    const useStyles = makeStyles(theme => ({
        amountDiv: {backgroundColor: theme.pageBackground, zIndex: 2}
    }));
    const classes = useStyles();

    const {
        language,
        preDefinedAmounts, customValue,
        paymentValue, setPaymentValue,
        selectedValue, setSelectedValue,
        toggleCustomValueField, setToggleCustomValueField
    } = useContext(PaymentContext);
    const {home} = language;

    // Handle the payment selector value changes
    const handlePaymentValueChange = (event, newValue) => {

        if(!newValue && paymentValue.isOther){
            setToggleCustomValueField(!toggleCustomValueField)
            return
        }

        // If selection not changed do notting
        if(!newValue && !paymentValue.isOther) return;

        // Parse the new value (newly selected value)
        const selected = parseInt(newValue);
        setSelectedValue(selected);

        if(newValue === customValue.paymentValue){
            setToggleCustomValueField(!toggleCustomValueField)
            return
        }

        const selectedPayment = preDefinedAmounts.find( amount => amount.paymentValue === selected);

        // If new value not null
        // Which means, selected value is a "new value"
        if (selected) {
            // If its a new value, set it as payment value
            setPaymentValue(selectedPayment)
            // Check if custom value form is visible
            // If so close the form
            if (toggleCustomValueField)
                setToggleCustomValueField(!toggleCustomValueField)
        }

    };

    return (
        <Grid container item direction="column" className={classes.amountDiv}>
            <Typography variant="caption">{home?.selectAmount}</Typography>
            <Divider style={{marginBottom: 10}}/>

            <ToggleButtonGroup
                value={selectedValue}
                exclusive
                onChange={handlePaymentValueChange}
                style={{zIndex: 1}}
            >
                {
                    preDefinedAmounts.map((amount, key) => (
                        <ToggleButton value={amount.paymentValue} key={key}>
                            <Typography variant="h5" value={amount.paymentValue} style={{fontWeight: 'bold'}}>
                                {amount.label} ₺
                            </Typography>
                        </ToggleButton>
                    ))
                }

                {
                    customValue.isVisible &&
                    <ToggleButton value={customValue.paymentValue}>
                        <Typography
                            variant="h5"
                            value={customValue.paymentValue}
                            style={{textTransform: 'none', fontWeight: 'bold'}}>
                            {customValue.label}
                        </Typography>
                    </ToggleButton>
                }

            </ToggleButtonGroup>
        </Grid>
    )
}

export default AmountSelector;