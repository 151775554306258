import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { useContext } from "react";

import { PaymentContext } from "../Contexts/PaymentContext";

import { makeStyles } from "@material-ui/core/styles";
import style from "./style";

export default () => {

    // Styling
    const useStyles = makeStyles(theme => ({
        customAmountInput: {
            "& .MuiInputLabel-outlined": {
                color: theme.textColor
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
                color: theme.textColor
            },
            '& .MuiOutlinedInput-root': {
                color: theme.textColor,
                '& fieldset': { borderColor: 'rgba(0, 0, 0, 0.12)' },
                '&:hover fieldset': { borderColor: 'rgba(0, 0, 0, 0.12)' },
                '&.Mui-focused fieldset': {
                    borderColor: theme.backgroundColor,
                },
            },
        },
        customAmountButton:{
            backgroundColor: theme.amountBackgroundColor,
            color: theme.amountTextColor,
            '&:hover': { backgroundColor: theme.backgroundColor },
        }
    }));
    const classes = useStyles();

    // Context
    const {
        language,
        preDefinedAmounts, setPaymentValue,
        toggleCustomValueField, setToggleCustomValueField,
        setSelectedValue,
        customValue, setCustomValue
    } = useContext(PaymentContext);
    const {home} = language;

    const {register, reset, formState: { errors }, handleSubmit: customHandleSubmit} = useForm({mode: 'onBlur'});

    // Custom value submit handler
    const onSubmit = ({newCustomValue}) => {

        // Parse the incoming value to integer
        let enteredCustomValue = parseInt(newCustomValue);

        // Check entered value available in pre defined amounts
        const isPre = preDefinedAmounts.some( amount => amount.paymentValue === enteredCustomValue );

        // If so, dont set the custom value
        if(!isPre)
            setCustomValue({...customValue, label: `${enteredCustomValue} ₺`, paymentValue: enteredCustomValue, eventAmount: enteredCustomValue});
        // Otherwise, reset the custom value
        else
            setCustomValue({...customValue, label: "Diğer", paymentValue: -1});

        setSelectedValue(enteredCustomValue);
        // Set the payment
        setPaymentValue({label: `${enteredCustomValue} ₺`, paymentValue: enteredCustomValue, eventAmount: enteredCustomValue, isOther: !isPre});
        // Reset the form everytime, customValue toggles (react-hook-form requirement)
        reset();
        // Close the form
        setToggleCustomValueField(false);

    }

    // Custom amount rules
    const rules = {
        required: { value: true, message: 'Lütfen geçerli bir tutar giriniz.'},
        min: { value: 1, message: 'Lütfen geçerli bir tutar giriniz.'},
        max: { value: 500, message: "Üst limit ₺500'dir."},
    }

    return (
        <AnimatePresence>
            {
                toggleCustomValueField &&
                    <motion.div
                        initial={{ opacity: 0, y: -48 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -48 }}
                        style={style.customValueMotion}
                    >
                        <form onSubmit={customHandleSubmit(onSubmit)}>
                            <Grid container alignItems="center" justifyContent="space-around">

                                <TextField
                                    {...register("newCustomValue", rules)}
                                    id="newCustomValue"
                                    name="newCustomValue"
                                    label={home.customAmountText}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    className={classes.customAmountInput}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    className={classes.customAmountButton}
                                >
                                    <Typography variant="button">
                                        {home.customAmountButton}
                                    </Typography>
                                </Button>

                            </Grid>

                            {
                                errors &&
                                <Typography variant="caption" style={style.customValueError} >
                                    {errors?.customValue?.message}
                                </Typography>
                            }

                        </form>

                    </motion.div>
            }
        </AnimatePresence>
    )
}