const defaults = {
    home:{
        balance: "Güncel Bakiyeniz",
        selectAmount: "Lütfen yüklemek istediğiniz tutarı seçiniz",
        selectCard: "Lütfen kredi kartı seçiniz",
        payButton: "Yükleme Yap",
        addNewCard: "Yeni Kart Ekle",
        customAmountText: "Tutar Giriniz",
        customAmountButton: "Onay"
    },
    addNewCard: {
        cardNumber: "Kart Numarası",
        nameSurname: "İsim Soyisim",
        AA: "AA",
        YYYY: "YYYY",
        CVC: "CVC",
        provider: "PayTR Ödeme Çözümleri",
        infoText: ["Kredi kartı bilgileriniz, güvenli ödeme alt yapısı", "tarafından saklanmaktadır. Kartınızdan 1 kuruşluk doğrulama işlemi yapılacak ve iade edilecektir."],
        saveButton: "Kaydet",
        backButton: "Geri",
    },
    modals: {
        cancelButton: "İptal",
        approveButton: "Onay",
        card: "Kart:",
        amount: "Tutar:",
        paymentApproval: {
            infoHeader: "Yükleme Onayı",
            text: "İşleminizi onaylıyor musunuz?",
        },
        deleteApproval: {
            infoHeader: "Kayıtlı Kartı Sil",
            text: "Kartı silmek istediğinize emin misiniz?",
        },
        error: {
            payErrorHeader: "Yükleme Yapılırken Hata Oluştu!",
            cardErrorHeader: "Kart Kaydedilirken Hata Oluştu!",
            checkCardInfo: "Lütfen kart bilgilerinizi kontrol edin.",
            tryAgain: "Lütfen sonra tekrar deneyiniz.",
            amountError: "Lütfen geçerli bir tutar seçiniz.",
            cardError: "Lütfen yükleme yapılacak kartınızı seçiniz.",
            thereDSError: "3D Secure doğrulama sırasında hata oluştu.",
        },
        success: {
            paySuccessHeader: "Yükleme Başarılı!",
            cardSuccessHeader: "Kart Kaydedildi!",
            paymentSuccess: "İşleminiz başarıyle gerçekleşti, bakiyeniz banka onayından sonra güncellenecektir.",
            cardSaved: "Kartınız başarıyla kaydedildi."
        }
    }
}

export default defaults;
