import {useContext, useEffect} from "react";

import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Refresh} from '@material-ui/icons';

import {motion} from "framer-motion";

import {PaymentContext} from "../Contexts/PaymentContext";

import LoadingCircle from "../Shared/LoadingCircle";
import CustomValueForm from "./CustomValueForm";
import AmountSelector from "./AmountSelector";
import CreditCardSelector from "./CreditCardSelector";
import {useAPI} from "../Hooks/useAPI";

const PaymentPage = () => {

    const {getMember, refreshMember} = useAPI();

    // Styling
    const useStyles = makeStyles(theme => ({
        paymentContainer: {
            backgroundColor: theme.pageBackground,
            height: '100%',
            position: "absolute",
            flexWrap: 'nowrap',
            padding: '25px 16px 20px 16px'
        },
        button: {
            minHeight: 50,
            backgroundColor: theme.paymentButtonBackgroundColor,
            color: theme.paymentButtonTextColor
        }
    }));
    const classes = useStyles();

    const {
        language,
        paymentMember,
        paymentCard,
        paymentValue,
        toggleCustomValueField,
        isMemberLoading,
        setIsMemberLoading,
        setModalInfo,
        setShowModal
    } = useContext(PaymentContext);
    const {home, modals} = language;

    useEffect(() => {
        // Refresh the member everytime page load
        const fetchMember = async () => {
            // Get the member
            const member = await getMember();
            // Disable loading if there is no error
            if (member?.status !== 'error')
                setIsMemberLoading(false)
        };
        fetchMember();
    }, []);

    const handlePayment = () => {
        // Do manuel checks
        // Check payment amount
        if (!paymentCard) {
            setModalInfo({
                infoType: "error",
                infoHeader: modals.error.payErrorHeader,
                infoText: modals.error.cardError,
            });
            setShowModal(true);
            return;
        }

        // Check the payment amount
        if (!paymentValue || paymentValue === -1) {
            setModalInfo({
                infoType: "error",
                infoHeader: modals.error.payErrorHeader,
                infoText: modals.error.amountError,
            });
            setShowModal(true);
            return;
        }

        // Display loading modal
        setModalInfo({
            infoType: "paymentApproval",
            infoHeader: modals.paymentApproval.infoHeader,
            infoText: modals.paymentApproval.text
        });
        setShowModal(true);
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={classes.paymentContainer}
            component={motion.div}
            initial={{opacity: 1}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <Grid
                container item
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid container item direction="column" alignItems="center" style={{marginBottom: 10}}>
                    <Typography variant="h6">{home?.balance}</Typography>
                    {
                        isMemberLoading ?
                            <LoadingCircle/>
                            :
                            <Typography variant="h3">
                                {Math.floor(paymentMember.pointsBalance / 100)} ₺
                                <Refresh
                                    fontSize="small"
                                    color="disabled"
                                    onClick={() => refreshMember()}
                                    style={{marginLeft: 10}}
                                />
                            </Typography>
                    }
                </Grid>

                <AmountSelector/>

                <CustomValueForm show={toggleCustomValueField}/>

            </Grid>

            <Grid container item direction="column" justifyContent="flex-end">

                <CreditCardSelector isLoading={isMemberLoading}/>

                <Button
                    variant="contained"
                    fullWidth size="large"
                    className={classes.button}
                    onClick={handlePayment}
                >
                    {home?.payButton}
                </Button>

            </Grid>

        </Grid>
    )
}

export default PaymentPage;