import {Grid} from '@material-ui/core';

import LoadingCircle from "./LoadingCircle";

const FullPageLoading = () => {
    return (
        <Grid container alignItems="center" justifyContent="center" style={{height: '100vh'}}>
            <LoadingCircle size={100} style={{color: "black"}} />
        </Grid>
    )
}

export default FullPageLoading;