import {useContext} from "react";
import axios from "axios";
import jwt from 'jsonwebtoken'
import publicIp from "public-ip";

import {PaymentContext} from "../Contexts/PaymentContext";
import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import ThemeOptions from "../styles/theme";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.4:4000' : process.env.REACT_APP_BONOBO_ADDON_API_URL;

const generateToken = (identifier, businessID) => {

    // If there is a commonExtId that means,
    // user already been identified therefore continue with commonExtId
    if(identifier.commonExtId)
        identifier = { commonExtId: identifier.commonExtId }

    const payload = {
        ...identifier,
        "locId": businessID
    };

    return jwt.sign(payload, process.env.REACT_APP_JWT_SECRET);
};

const timeout = (delay) => new Promise(res => setTimeout(res, delay));

export function useAPI() {

    const params = new URLSearchParams(window.location.search);
    let commonExtId = params.get('commonExtId');
    let temporaryToken = params.get('temporaryToken');
    let locId = params.get('locId');
    let identifier = { commonExtId, temporaryToken }

    const {
        language, setLanguage,
        businessID, setBusinessID,
        paymentMember,
        paymentProvider,
        paymentCard, setPaymentCard,
        paymentValue, setPaymentValue,
        setPreDefinedAmounts,
        setPaymentProvider,
        setPaymentMember,
        setSetCreditCards,
        setThreeDSHtmlContent,
        setCustomValue,
        setSelectedValue,
        setShowModal,
        setModalInfo,
        setIsMemberLoading
    } = useContext(PaymentContext);

    const getBusiness = async () => {

        const config = {
            method: 'post',
            url: `${baseURL}/Businesses/getBusiness`,
            data: {businessID: locId}
        };

        let data = {};

        try {
            const response = await axios(config);
            data = response.data.business;
            setBusinessID(response.data.business.id)
        } catch (err) {
            return err
        }

        const {preDefinedAmounts, customValue, themeColors, providerSettings, appTexts} = data.mobilePaymentSettings;

        // Set themeColors
        let theme = createTheme(ThemeOptions(themeColors));
        theme = responsiveFontSizes(theme);

        // preDefinedAmounts
        setPreDefinedAmounts(preDefinedAmounts)
        setPaymentValue(preDefinedAmounts[0])
        setSelectedValue(preDefinedAmounts[0].paymentValue)
        setCustomValue({...customValue, paymentValue: -1, eventAmount: -1})

        // set provider info
        setPaymentProvider(providerSettings.provider)

        // Set the language
        if(appTexts) setLanguage({...language, ...appTexts})

        return {theme};

    }

    const getMember = async () => {
        // Generate a JWT without email
        // This will only bring member info
        let JWT = paymentMember?.JWT;

        if(!JWT) JWT = generateToken(identifier, businessID)

        const config = {
            method: 'post',
            url: `${baseURL}/MobilePayment/${paymentProvider}/getPaymentMember`,
            headers: {Authorization: `Bearer ${JWT}`}
        };

        let data = {};

        try {
            // Make the call for member
            const response = await axios(config);
            // Data shortcut
            data = response.data;
            const {commonExtId, email} = response.data;
            // If there is any error, return early
            if(data.status === 'error') return data;
            // This response will bring email address of the member as well
            // email needs to be injected in JWT for feature calls
            // So, generate a new token with email address
            data.JWT = generateToken({commonExtId, email, temporaryToken}, businessID);
            // Set Payment Member
            setPaymentMember(data);
            // Set Cards
            setSetCreditCards(response.data.cardDetails);
            // Set first cards as defaults
            setPaymentCard(response.data.cardDetails[0]);
            return data;
        } catch (err) {
            return err.response.data
        }
    }

    const addNewCard = async (encryptedCard) => {
        const config = {
            method: 'post',
            url: `${baseURL}/MobilePayment/${paymentProvider}/addNewCard`,
            headers: {Authorization: `Bearer ${paymentMember.JWT}`},
            data: {data: encryptedCard}
        };

        try {
            const response = await axios(config);
            return response.data;
        } catch (err) {
            return err.response.data
        }
    }

    const deleteCard = async (cardToken, lastFourDigits) => {
        const config = {
            method: 'post',
            url: `${baseURL}/MobilePayment/${paymentProvider}/deleteCard`,
            headers: {Authorization: `Bearer ${paymentMember.JWT}`},
            data: {
                cardUserKey: paymentMember.cardUserKey,
                cardToken,
                lastFourDigits
            }
        };

        try {
            const response = await axios(config);
            return response.data;

        } catch (err) {
            return err.response.data
        }

    }

    const refreshMember = async () => {
        setIsMemberLoading(true)
        await timeout(1500)
        await getMember();
        setIsMemberLoading(false);
    }

    const makePayment = async (CVC) => {

        if(paymentCard.isCVCRequired === '1' && !CVC){
            setModalInfo({
                infoType: "CVC",
                infoHeader: "Güvenlik Kodu Gerekli!",
                infoText: `Lütfen kartınızın arkasındaki güvenlik kodunu giriniz. `,
            });
            setShowModal(true);
            return;
        }

        let res;
        const config = {
            method: 'post',
            url: `${baseURL}/MobilePayment/${paymentProvider}/makePayment`,
            headers: {Authorization: `Bearer ${paymentMember.JWT}`},
            data: {
                ipAddress: await publicIp.v4(),
                cardUserKey: paymentMember.cardUserKey,
                cardToken: paymentCard.cardToken,
                lastFourDigits: paymentCard.lastFourDigits,
                amount: paymentValue.paymentValue * 100,
                eventAmount: paymentValue.eventAmount * 100,
                cardCVC: CVC
            }
        };

        // make the call
        try {
            res = await axios(config);
            res = res.data;
        } catch (err) {
            return err.response.data
        }

        // If there is an error, display an error modal
        if (res.status !== 'success') {
            setModalInfo({
                infoType: "error",
                infoHeader: "Ödeme Yapılırken Hata Oluştu!",
                infoText: `Lütfen sonra tekrar deneyiniz.`,
            });
            return;
        }

        // If there is no conversationId,
        // that means its not a 3DS payment and payment is successful
        if (!res.threeDSHtmlContent) {
            await refreshMember();
            setModalInfo({
                infoType: "success",
                infoHeader: "Ödeme Başarılı!",
                infoText: `Ödemeniz başarıyle gerçekleşti, bakiyeniz banka onayından sonra güncellenecektir.`,
            });
            return;
        }

        // If there is a there DS Html Content in the response that means,
        // its a 3DS payment, so display 3DS screen
        if(res.threeDSHtmlContent){
            setThreeDSHtmlContent(res.threeDSHtmlContent);
            setModalInfo({infoType: "3DS"});
        }


    };

    return {getBusiness, getMember, addNewCard, deleteCard, makePayment, refreshMember}
}