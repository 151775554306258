import React, {memo} from "react";
import Lottie from 'react-lottie-player'

const LottiePlayer = memo(({animationData, approved = false, segments, onComplete, ...rest}) => {

    return (
        <Lottie
            play
            animationData={animationData}
            segments={segments}
            loop={false}
            onComplete={onComplete}
            style={{ width: "100%", height: 150, maxHeight: '100%' }}
            {...rest}
        />
    );
});

export default LottiePlayer;