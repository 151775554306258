import {useContext} from "react";
import {useHistory} from "react-router";

import {Box, Divider, Typography} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";

import { useLongPress } from 'use-long-press';

import {PaymentContext} from "../Contexts/PaymentContext";

import CreditCard from "./CreditCard";
import LoadingCircle from "../Shared/LoadingCircle";

const CreditCardSelector = ({isLoading}) => {

    // Styling
    const useStyles = makeStyles(() => ({

        container: {
            width: '100%',
            marginBottom: 10,
            backgroundColor: 'transparent'
        },

        creditCardSelect: {
            width: '100%',
            padding: 5,
            backgroundColor: 'transparent',
            opacity: 1,
            "&.Mui-selected": {
                borderRadius: 0
            },
        }

    }));
    const classes = useStyles();

    const {
        language,
        creditCards,
        paymentCard, setPaymentCard,
        setShowModal, setModalInfo, setIsMemberLoading
    } = useContext(PaymentContext);
    const history = useHistory();

    const {home, modals} = language;

    // Handle the credit card selector
    const handleCreditCardChange = (event, newValue) => {
        // If addNew selected, redirect to add new card page
        if (newValue === 'addNew')
            history.push("/addNewCard" + window.location.search)

        // If there is any new value and it's not add new
        // Set selected value
        if (newValue && newValue !== 'addNew')
            setPaymentCard(newValue)
    };

    const handleDelete = ({target}) => {
        const {cardToken, lastFourDigits} = creditCards.find(card => card.cardToken === target.id)

        setIsMemberLoading(true)
        setModalInfo({
            infoType: "deleteApproval",
            infoHeader: modals.deleteApproval.infoHeader,
            infoText: modals.deleteApproval.text,
            cardToken,
            lastFourDigits
        })
        setShowModal(true)
    };

    const defaultOptions = {captureEvent: true, threshold: 500};
    const longPressEvent = useLongPress(handleDelete, defaultOptions);

    return (
        <Box className={classes.container}>

            <Typography variant="caption">{home?.selectCard}</Typography>

            <Divider style={{marginBottom: 10}}/>

            {
                isLoading ?
                    <LoadingCircle size={75}/>
                    :
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={paymentCard}
                        exclusive
                        onChange={handleCreditCardChange}
                        style={{width: '100%'}}
                    >
                        {
                            creditCards.map((card, key) => (
                                <ToggleButton
                                    {...longPressEvent}
                                    value={card}
                                    aria-label={card.cardBankName}
                                    className={classes.creditCardSelect}
                                    key={key}
                                >
                                    <CreditCard
                                        cardType={card.cardAssociation}
                                        bank={card.cardBankName}
                                        last4Digit={card.lastFourDigits}
                                        cardToken={card.cardToken}
                                    />
                                </ToggleButton>
                            ))
                        }
                        {
                            !(creditCards.length >= 3) &&
                            <ToggleButton value="addNew" aria-label="addNewCard" className={classes.creditCardSelect}>
                                <CreditCard cardType="addNew" text={home?.addNewCard}/>
                            </ToggleButton>
                        }

                    </ToggleButtonGroup>
            }
        </Box>
    )
}

export default CreditCardSelector;