import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import LottiePlayer from "../Lottie";

import LoadingCircle from "../LoadingCircle";
import {useAPI} from "../../Hooks/useAPI";

import {Button, Grid, Typography, TextField, Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {PaymentContext} from "../../Contexts/PaymentContext";

import successAnimation from "../../Animations/success.json";
import errorAnimation from "../../Animations/error.json";
import approvalAnimation from "../../Animations/approval.json";
import deleteCardAnimation from "../../Animations/deleteCard.json";

const timeout = (delay) => new Promise(res => setTimeout(res, delay));

const InfoModalContent = () => {

    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        infoText: {
            marginBottom: theme.spacing(2),
        },
        closeButton: {
            padding: 7,
            borderColor: "#C2464A",
            color: "#C2464A"
        },
        approvalButton: {
            padding: 7,
            backgroundColor: "#78C08C",
            color: 'white'
        },
        container: {
            padding: theme.spacing(2, 4, 3)
        },
        gutterBottom: {paddingBottom: theme.spacing(2)}
    }));
    const classes = useStyles();
    const {deleteCard, makePayment, refreshMember} = useAPI();

    const {
        language,
        paymentCard,
        paymentValue,
        showModal,
        setShowModal,
        modalInfo,
        setIsMemberLoading
    } = useContext(PaymentContext)
    const {modals} = language;
    const [close, setClose] = useState(false)
    const [segments, setSegments] = useState()
    const [animationData, setAnimationData] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);
    const [CVC, setCVC] = useState('');
    const [CVCError, setCVCError] = useState(false);

    const [isSuccess, setIsSuccess] = useState(modalInfo?.infoType === 'success');
    const [isError, setIsError] = useState(modalInfo?.infoType === 'error');
    const [isCVC, setIsCVC] = useState(modalInfo?.infoType === 'CVC');
    const isDeleteApproval = modalInfo?.infoType === 'deleteApproval'
    const isPaymentApproval = modalInfo?.infoType === 'paymentApproval'
    const isCVCApproval = modalInfo?.infoType === 'CVC';
    const isApproval = isDeleteApproval || isPaymentApproval || isCVCApproval;

    const waitApproval = () => close && setShowModal(false);
    const waitAndClose = async () => {
        await timeout(2500);
        setShowModal(false)
        setIsMemberLoading(false)
        history.push('/' + window.location.search);
    };

    const handleClose = () => {
        setShowModal(false);
        setIsMemberLoading(false);
    };
    const handleApproval = async () => {
        setIsLoading(true);
        if (isDeleteApproval) {
            const isDeleted = await deleteCard(modalInfo.cardToken, modalInfo.lastFourDigits);
            if (isDeleted.status === 'success') {
                await refreshMember();
                setSegments([40, 93]);
                setClose(true);
            }
            return;
        }

        if (isPaymentApproval) await makePayment();

        if (isCVCApproval) {
            // Validate the CVC's length
            if (CVC.length < 3) {
                setCVCError(true);
                setIsLoading(false);
                return;
            }
            await makePayment(CVC);
        }
    }

    // Execute related functions
    let onComplete = () => {
        if (isDeleteApproval || isPaymentApproval) waitApproval();
        if (isSuccess) waitAndClose();
    }

    useEffect(() => {
        if (modalInfo?.infoType === 'success') {
            setIsSuccess(true);
            setAnimationData(successAnimation)
        }

        if (modalInfo?.infoType === 'error') {
            setIsError(true);
            setAnimationData(errorAnimation)
        }

        if (modalInfo?.infoType === 'CVC') {
            setIsLoading(false);
            setIsCVC(true);
            setAnimationData(approvalAnimation)
        }
    }, [modalInfo])

    useEffect(() => {

        setIsLoading(false);
        setClose(false);
        setSegments([]);

        if (isDeleteApproval) {
            setAnimationData(deleteCardAnimation);
            setSegments([0, 40]);
            return;
        }

        if (isError) {
            setAnimationData(errorAnimation)
            return;
        }

        if (isSuccess) {
            setAnimationData(successAnimation)
            return;
        }

        if (isPaymentApproval) {
            setAnimationData(approvalAnimation);
            return;
        }

        if (isCVC) {
            setAnimationData(approvalAnimation);
            return;
        }

    }, [showModal]);

    return (
        <Grid container alignContent="center" className={classes.container}>
            <LottiePlayer
                approved={close}
                segments={segments}
                speed={2}
                play={showModal}
                animationData={animationData}
                onComplete={onComplete}
            />

            <Grid container item direction="column">
                <h2>{modalInfo?.infoHeader}</h2>
                {
                    isApproval &&
                    <>
                        <Grid item className={!isPaymentApproval ? classes.gutterBottom : ''}>
                            <Box fontWeight='fontWeightBold' display='inline'> {modals?.card} </Box>
                            <Box display='inline'> **** **** **** {paymentCard?.cardAlias || '****'} </Box>
                        </Grid>
                        {
                            isPaymentApproval &&
                            <Grid item className={classes.gutterBottom}>
                                <Box fontWeight='fontWeightBold' display='inline'> {modals?.amount} </Box>
                                <Box display='inline'> {paymentValue.paymentValue} ₺ </Box>
                            </Grid>
                        }
                    </>
                }
                <Typography
                    variant="body1"
                    className={classes.infoText}
                >
                    {modalInfo?.infoText}
                </Typography>
                {
                    isCVC &&
                    <TextField
                        value={CVC}
                        label="CVC"
                        variant="outlined"
                        fullWidth
                        className={classes.infoText}
                        error={CVCError}
                        helperText={CVCError ? "Lütfen kontrol ediniz!" : ''}
                        onChange={e => setCVC(e.target.value.replace(/\D/, ''))}
                        required
                        inputProps={{
                            inputMode: 'numeric',
                            type: 'tel',
                            maxLength: 3
                        }}
                    />
                }
            </Grid>

            <Grid container item spacing={2}>
                {
                    !isSuccess &&
                    <Grid item xs={isApproval ? 6 : 12}>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={classes.closeButton}
                            fullWidth
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <LoadingCircle size={26} style={{color: 'white'}}/>
                                    :
                                    modals.cancelButton
                            }
                        </Button>
                    </Grid>
                }

                {
                    isApproval &&
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.approvalButton}
                            fullWidth
                            onClick={handleApproval}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <LoadingCircle size={26} style={{color: 'white'}}/>
                                    :
                                    modals.approveButton
                            }
                        </Button>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default InfoModalContent;