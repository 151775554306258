import {lazy, Suspense, useContext, useEffect, useState} from "react"
import {useHistory} from "react-router"

import {makeStyles} from "@material-ui/core/styles"
import {Grid} from "@material-ui/core"
import {motion} from "framer-motion"

import LoadingCircle from "../Shared/LoadingCircle"

import {PaymentContext} from "../Contexts/PaymentContext"

const CreditCard = lazy(() => import('./CreditCard'))
const CardForm = lazy(() => import('./CardForm'))

const AddCardPage = () => {

    const {language, paymentMember, paymentProvider} = useContext(PaymentContext)
    const {addNewCard} = language;

    // Styling
    const useStyles = makeStyles(theme => ({
        newCardContainer: {
            zIndex: 3,
            backgroundColor: theme.pageBackground,
            height: '100%',
            position: "absolute",
            flexWrap: 'nowrap',
            padding: '25px 16px 20px 16px',
            boxShadow: "1px 1px 30px -5px rgba(0,0,0,0.50);"
        }
    }))
    const classes = useStyles()

    const history = useHistory()

    // Card Details
    const [cardNumber, setCardNumber] = useState('')
    const [cardholderName, setCardholderName] = useState(paymentMember?.fullName)
    const [CVC, setCVC] = useState('')
    const [expiryMM, setExpiryMM] = useState('')
    const [expiryYYYY, setExpiryYYYY] = useState('')

    const bundleCardStates = {
        paymentProvider,
        cardNumber, setCardNumber,
        cardholderName, setCardholderName,
        CVC, setCVC,
        expiryMM, setExpiryMM,
        expiryYYYY, setExpiryYYYY
    }

    // Check if its came from home page,
    // In other means, disable refresh or manuel enter
    useEffect(() => {
        history.action === 'POP' && history.push("/" + window.location.search)
        // Need a cleanup func., otherwise,
        // it tries to push even its removing from the DOM
        return () => {}
    }, [])

    return (
        <Grid
            container wrap="nowrap"
            direction="column"
            alignItems="center"
            className={classes.newCardContainer}
            component={motion.div}
            initial={{x: '100vw'}}
            animate={{x: 0}}
            exit={{x: '100vw'}}
            transition={{duration: 0.5}}
        >

            <Suspense fallback={<LoadingCircle />}>

                <CreditCard
                    number={cardNumber}
                    name={cardholderName}
                    cvc={CVC}
                    expiry={expiryMM + expiryYYYY}
                    placeholders={{name: addNewCard.nameSurname}}
                />

                <CardForm {...bundleCardStates} />

            </Suspense>

        </Grid>
    )
}

export default AddCardPage