import {useContext} from "react";

import {makeStyles} from '@material-ui/core/styles';
import {Backdrop, Fade, Modal, Paper} from '@material-ui/core';

import {PaymentContext} from "../../Contexts/PaymentContext";
import useWindowSize from "../../Hooks/useWindowSize";

import LoadingCircle from "../LoadingCircle";

import InfoModal from "../InfoModal/InfoModal";
import ThereDS from "./3DSPage";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column",
        width: '90%',
        boxShadow: theme.shadows[5]
    }
}));

const Index = () => {

    const classes = useStyles();
    const {width: screenWidth} = useWindowSize();

    const {showModal, modalInfo} = useContext(PaymentContext)

    let isLoadingModal = modalInfo?.infoType === 'loading';
    const isModalType = ["isLoadingModal", "deleteApproval", "success", "error", "paymentApproval", "CVC"];
    const is3DS = modalInfo?.infoType === '3DS'

    return (
        <Modal
            className={classes.modal}
            open={showModal}
            onClose={() => {}}
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
            disableBackdropClick
        >
            <Fade in={showModal}>
                <Paper className={classes.paper}>
                    { isLoadingModal && <LoadingCircle size={screenWidth / 3}/>}

                    {
                        is3DS && <ThereDS />
                    }

                    {
                        isModalType.includes(modalInfo?.infoType) &&
                            <InfoModal />

                    }

                </Paper>
            </Fade>
        </Modal>
    )
}

export default Index;