import {createContext, useState} from 'react';

import languages from "../Shared/languages";

export const PaymentContext = createContext();

const PaymentContextProvider = ({children}) => {

    // Get the language
    const [language, setLanguage] = useState(languages)

    // Payment Member
    const [paymentMember, setPaymentMember] = useState(undefined)

    // Payment Provider
    const [paymentProvider, setPaymentProvider] = useState(undefined);

    // Business ID
    const [businessID, setBusinessID] = useState(undefined);

    // Payment Amounts
    const [preDefinedAmounts, setPreDefinedAmounts] = useState([]);
    const [customValue, setCustomValue] = useState({isVisible: false, label: "Diğer", paymentValue: -1, eventAmount: -1});
    const [selectedValue, setSelectedValue] = useState();

    // 3DS Page Content
    const [threeDSHtmlContent, setThreeDSHtmlContent] = useState();

    // Credit Cards
    const [creditCards, setSetCreditCards] = useState([]);
    const [paymentCard, setPaymentCard] = useState(creditCards[0] || {})

    // Utils
    const [toggleCustomValueField, setToggleCustomValueField] = useState(false);
    const [paymentValue, setPaymentValue] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(undefined);
    const [isMemberLoading, setIsMemberLoading] = useState(true);

    // Define Exported Variables
    const exportValues = {
        language, setLanguage,
        paymentMember, setPaymentMember,
        paymentProvider, setPaymentProvider,
        businessID, setBusinessID,
        preDefinedAmounts, setPreDefinedAmounts,
        paymentCard, setPaymentCard,
        paymentValue, setPaymentValue,
        threeDSHtmlContent, setThreeDSHtmlContent,
        creditCards, setSetCreditCards,
        toggleCustomValueField, setToggleCustomValueField,
        customValue, setCustomValue,
        selectedValue, setSelectedValue,
        showModal, setShowModal,
        modalInfo, setModalInfo,
        isMemberLoading, setIsMemberLoading
    }

    return (
        <PaymentContext.Provider value={exportValues}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentContextProvider;